.quotation-form-step-container {
  background-color: var(--light-primary);
  border: 1px solid #bebebe;
  border-radius: 12px;
}

.quotation-form-container {
  border-radius: 1rem;
  .header-row {
    padding: 0 24px;
    display: flex;
    align-items: flex-start;
    align-items: center;
  }

  .trip-details-tile {
    flex: 1 1 auto;
    font-size: 26px;
    font-weight: 400;
    color: var(--dark-secondary);
  }

  .trip-details-tile-wl {
    font-size: 1.3rem;
    line-height: 1.7rem;
    letter-spacing: 0.2px;
    font-weight: 700;
    flex: 11 auto;
  }

  .quotation-form-web {
    padding: 24px;
    color: var(--dark-primary);
    font-weight: 700;
    font-size: 18px;

    .button-custom {
      text-transform: unset;
      border: 1px solid var(--dark-light);
      color: var(--dark-primary);
      font-weight: 400;
      font-size: 16px;
      min-height: 32px;
      border-radius: 10px;
    }

    .destination-chip {
      background-color: var(--secondary);
      color: var(--light-primary);
      height: 22px;
      margin-right: 0.2rem;
      span {
        font-weight: 400;
      }
    }

    input {
      font-size: 14px;
      font-weight: 300;
    }

    .tooltip {
      display: inline-flex;
      border-radius: 6px;
      border: 2px solid var(--dark-primary);
      margin-left: 8px;
      cursor: pointer;
      color: var(--dark-primary);
      background-color: #fff;
      width: 14px;
      height: 14px;

      svg {
        width: 14px;
        height: 14px;
        margin-top: -2px;
        margin-left: -2px;
      }
    }

    .trip-type-help-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .row {
      margin-bottom: 16px;

      .control-header {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 16px;
        display: flex;
        align-items: center;
      }

      .trip-type-button {
        padding: 8px;
        min-width: 11rem;

        &:hover {
          background-color: transparent;
        }

        &.active {
          border: 1px solid #fff;
          background-color: var(--secondary);
          color: #fff;
          .trip-type-icon svg {
            color: #fff;
          }
        }

        .tooltip {
          position: absolute;
          top: 8px;
          right: 8px;
        }

        .trip-type-icon svg {
          font-size: 2rem;
        }
      }
      .destination-select,
      .MuiInputBase-root.lcss-wcyjgh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-94dxa4-MuiInputBase-root-MuiOutlinedInput-root,
      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-azcsr2-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 10px;
      }

      .destination-select-text {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .destination-select,
      .max-trip-duration-select {
        p {
          font-size: 14px;
          font-weight: 300;
        }

        fieldset {
          border-color: var(--dark-light);
        }

        &.Mui-error fieldset {
          border-color: var(--dark-primary);
        }
      }

      .date-picker {
        &:not(.w-auto) {
          width: 100%;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: var(--dark-light);
        }

        .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
          border-color: var(--secondary);
        }
      }

      .travellers-type-button,
      .addons-button {
        padding: 8px;
        min-width: 98px;
        width: auto;
        height: 32px;
        flex: 1;

        &.active {
          background-color: var(--primary);
          .traveller-type-button-span {
            color: var(--light-primary);
          }
        }
        &.TICK {
          font-size: 12px;
        }

        .traveller-type-button-span {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 13px;
          color: var(--dark-primary);
          svg {
            font-size: 20px;
            margin-right: 3px;
          }
        }
      }

      .travellers-age-row {
        .traveller-age-label {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          svg {
            font-size: 19px;
          }
        }
        .travellers-age-controls {
          display: flex;
          flex-direction: row;
          gap: 8px;
          flex-wrap: wrap;

          .age-input {
            input {
              padding: 10px 5px;
              height: 12px;
              text-align: center;
            }

            fieldset {
              border-color: var(--dark-light);
            }
          }

          .travellers-age-buttons {
            gap: 8px;
            display: flex;

            .travellers-age-button {
              min-width: 36px;
              padding: 0;
              height: 32px;
              color: var(--dark-primary);
              border: 1px solid var(--dark-light);

              &:hover {
                background-color: var(--light-secondary);
                color: var(--dark-secondary);
              }
            }
          }
        }
      }

      .promo-code-input {
        fieldset {
          border-color: var(--dark-light);
        }
      }

      .submit-button {
        height: 48px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        align-self: flex-end;
        justify-self: flex-end;
        width: auto;
        max-width: 100%;

        &:hover {
          background-color: var(--primary);
        }
      }

      .date-picker {
        input {
          padding-top: 0;
          padding-bottom: 0;
        }

        .date-picker-adornment {
          button {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 28px;
            left: 0;
            border-radius: 0px;
          }
        }
      }
    }
  }
}
#menu-destination {
  ul li {
    font-size: 12px;
    font-weight: 700;
    white-space: normal;
  }
}

.MuiDateCalendar-root {
  max-width: 260px;
}

.MuiPickersCalendarHeader-label {
  font-size: 14px;
}

.MuiPickersSlideTransition-root {
  min-height: 195px !important;
}

.MuiPickersDay-root {
  width: 30px !important;
  height: 30px !important;
  font-size: 0.6rem !important;
}

.MuiDayCalendar-weekDayLabel {
  width: 30px !important;
  height: 30px !important;
}

.MuiYearCalendar-root {
  max-width: 260px !important;
  max-height: 195px !important;

  .MuiPickersYear-yearButton {
    height: 34px;
    width: 50px;
  }
}

.tripDetailsContainer {
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 2fr);
  gap: 2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mobile-view-calendar {
  .MuiDateCalendar-root {
    max-width: 320px;
  }

  .MuiPickersSlideTransition-root {
    min-height: 240px !important;
    &-MuiDayCalendar-slideTransition {
      height: 320px;
    }
  }

  .MuiDayCalendar-header {
    justify-content: space-evenly;
  }

  .MuiDayCalendar-monthContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 240px;
  }

  .MuiDayCalendar-weekContainer {
    justify-content: space-evenly;
  }

  .MuiPickersDay-root,
  .MuiDayCalendar-weekDayLabel {
    font-size: 1rem !important;
    margin-bottom: 8px;
  }
}

@media (max-width: 600px) {
  .quotation-form-web .button-custom {
    max-height: fit-content;
  }

  .quotation-form-step-container {
    margin: 0 0.75rem;
  }

  .quotation-form-container {
    border: none !important;
    padding: 2rem 0 !important;


    .quotation-form-web {
      padding: 0.8125rem;
    }
  }

  .addons-row,
  .destination-and-date-row,
  .travellers-type-and-age-row {
    display: flex !important;
    flex-direction: column !important;
  }

  .trip-type-button {
    min-width: unset !important;
    flex-basis: 100%;
  }

  .promo-code-and-submit-button {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .tripDetailsContainer {
    width: 100%;
    display: flex;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }

  .travellers-type-and-age-row {
    margin-bottom: 0 !important;
  }

  .promo-code-and-submit-button {
    grid-template-columns: 1fr !important;
    & > fieldset {
      width: 100%;
      .promo-code-input {
        width: 100%;
      }
    }
    & > button {
      width: 100%;
    }
  }

  .submit-button {
    align-self: baseline !important;
    justify-self: baseline !important;
    text-transform: none !important;
  }
}
@media (max-width: 400px) {
  .quotation-form-web .row .trip-type-button .trip-type-icon svg {
    font-size: 2rem;
  }
  .progress-stepper-container {
    display: none;
  }
}

.helpIcon {
  font-size: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.wl-link {
  color: var(--secondary-light);
  text-decoration: none;
}
