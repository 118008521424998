[data-testid='QuotationSummaryBlock'] {
  width: 100%;
}
.hidden {
  width: 0 !important;
}

.grow {
  flex-grow: 1;
}

.promotionSummary {
  display: flex;
  align-items: center;
  color: var(--secodary-light);
}

.clearPromotion {
  color: red;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
