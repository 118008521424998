.summary-desktop {
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100% !important;
  font-size: 0.85rem;
  box-shadow: none;
  border: 1px solid var(--dark-light);
  border-radius: 0.75rem;

  .collapse-bar {
    display: inline-flex;
    color: var(--text-primary);
  }
}

.summary-mobile {
  border-radius: 0.75rem !important;
  padding: 1rem 2rem;
}

.clearDiscountPromotion {
  color: red;
  cursor: pointer;
}

.summary-desktop,
.summary-mobile {
  .summary-box {
    position: relative;
    margin-bottom: 0.4rem;
    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
    font-size: 0.9rem;
    line-height: 1.1rem;
    .summary-title {
      font-weight: 600;
    }
  }

  .summary-quote-id {
    font-size: 0.5rem;
    color: var(--dark-secondary);
    line-height: 0.5rem;
    margin-top: 3px;
    display: flex;
    justify-content: flex-end;
  }

  .collapse-row {
    width: 25vw;
    height: 30px;
    position: absolute;
    top: -32px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid var(--dark-primary);
  }
}

.row-price {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}