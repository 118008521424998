@import 'shared/styles/default';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.quotation-header-container {
  .quotation-header {
    color: var(--text-primary);
    h2 {
      margin-block-start: 0.2rem;
      margin-block-end: 0.2rem;
    }
  }
}

.avatar {
  background-color: var(--light-secondary) !important;
  color: var(--text-primary) !important;
  svg {
    color: var(--text-primary) !important;
  }
}
