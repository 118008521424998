.quotation-summary-wrapper {
  display: flex;
  justify-content: center;
  height: 2.2rem;
}

.quotation-summary-mobile {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  min-width: 20rem;
  padding: 1.5rem 0;
}
