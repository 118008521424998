.progress-stepper-mobile-wrapper {
  display: flex;
  height: 4rem;
  padding: 1.25rem 0;

  background-color: #fff;

  &:nth-child(odd) {
    flex-basis: 30px;
  }
}

.progress-stepper-mobile-wrapper .transparent-btn {
  position: relative;
  top: -0.1rem;

  background-color: transparent;
  border: none;
  color: #000;
}

.progress-stepper-mobile-wrapper .transparent-btn.hidden {
  opacity: 0;
  pointer-events: none;
}

.progress-stepper-mobile-wrapper .transparent-btn[disabled] {
  opacity: 0.4;
  pointer-events: none;
}

.progress-stepper-mobile-wrapper .current-step-information {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-basis: 100%;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
}

.progress-stepper-mobile-wrapper .current-step-information .current-step-badge {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 1.625rem;
  aspect-ratio: 1/1;
  background-color: #d3021a;
  border-radius: 50%;
  color: #fff;
}
