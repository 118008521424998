.button {
  border: 1px solid var(--dark-light);
  background: none !important;
  color: #212121 !important;
  border-radius: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  &:hover {
    border-color: var(--primary);
  }
}

.collapse-row-trip {
  left: 35% !important;
}

.label {
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-primary);
}

.promotion {
  .MuiSelect-select,
  .MuiOutlinedInput-input {
    font-size: 0.8rem;
  }
  .MuiOutlinedInput-root {
    height: 2rem;

    & input::placeholder {
      font-size: 0.8rem;
    }
  }
}