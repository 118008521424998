.collapse-row-helio {
  left: 70%;
}

.button {
  border: 1px solid var(--dark-light);
  background: none !important;
  color: #212121 !important;
  border-radius: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  &:hover {
    border-color: var(--primary);
  }
}