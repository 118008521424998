.progress-stepper .MuiStepper-root {
  .MuiStepLabel-label {
    margin-top: 8px;
    color: inherit; /* Ensure that the label text inherits the default color */
  }

  /* Target only the icon for active and completed steps */
  .Mui-active .MuiStepIcon-root,
  .Mui-completed .MuiStepIcon-root {
    color: #d30319; /* Change only the icon color */
  }

  .MuiStepLabel-label.Mui-active,
  .MuiStepLabel-label.Mui-completed {
    color: inherit;
  }
}

.progress-stepper:not(.whitelabel) {
/* Target only the icon for active and completed steps */
  .Mui-active .MuiStepIcon-root,
  .Mui-completed .MuiStepIcon-root {
    color: var(--primary); /* Change only the icon color */
  }
}

@media (max-width: 800px) {
  .progress-stepper {
    .MuiStepLabel-label {
      font-size: 0.75rem;
    }
  }
}
