.summary-element-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .label.summary {
    font-size: 1rem;
    font-weight: 400;
    color: #737373;
  }

  .label.trip {
    font-weight: 400;
    font-size: 1rem;
    color: var(--dark-primary);
  }

  .product-information-wrapper {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description {
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: capitalize;
    color: var(--dark-primary);
  }

  .description.summary {
    width: 50%;
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--dark-primary);
    text-wrap: balance;
  }
}
