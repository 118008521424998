.backdrop {
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0000004d;
  z-index: 1000;
  overflow-y: auto;
}

.backdrop-content {
  width: 80%;
  height: fit-content;
}