.open-quote-summary {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4rem;

    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    font-size: 1rem;
}