.quote-copy {
  color: var(--dark-secondary);
  font-size: 1.1rem;
  margin-bottom: 1rem;
  .quote-id {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 0.75rem;
    color: var(--dark-secondary);

    &.text-sm {
      font-size: 0.75rem;
    }

    &.text-md {
      font-size: 1.125rem;

      .quote-id-data {
        font-weight: 700;
      }
    }

    &.text-lg {
      font-size: 2rem;
    }

    &-data {
      color: var(--secodary-light);
      text-decoration: underline;
    }

    .MuiSvgIcon-root {
      color: var(--dark-light);
      font-size: 0.75rem;
      cursor: pointer;
    }
  }
}